import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID, ApplicationRef } from '@angular/core';
import { CommonModule, registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';

registerLocaleData(localeDe);

import { AppRoutingModule } from './app-routing.module';
import { AppComponent, AppConfig } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { environment } from '../environments/environment';
import { apiConfigFactory } from './core/helpers/factories/generatedApiConfigFactory';
import { ApiModule, BASE_PATH } from './generated-api';
import { MarkdownModule, MarkedOptions, MarkedRenderer } from 'ngx-markdown';
import { SafePipeModule } from 'safe-pipe';
import { MissingTranslationHandler, TranslateModule } from '@ngx-translate/core';
import { ErrorInterceptorService } from './core/interceptors/error-interceptor.service';
import { VirtualScrollerModule } from 'ngx-virtual-scroller';
import { MyMissingTranslationHandler } from './core/services/missing-translation-handler.service';
import { isAbsoluteLinkFast } from './shared/functions/utils';
import { FrontpageModule } from './modules/frontpage/frontpage.module';
import { GLOSSARY_IDENTIFIER } from './core/services/markdown-link-handler.service';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserAnimationsModule,
    HttpClientModule,
    CoreModule,
    HammerModule,
    FrontpageModule,
    ApiModule.forRoot(apiConfigFactory),
    MarkdownModule.forRoot({ markedOptions: { provide: MarkedOptions, useFactory: markedOptionsFactory } }),
    TranslateModule.forRoot({
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: MyMissingTranslationHandler
      }
    }),
    AppRoutingModule,
    MatToolbarModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    SafePipeModule,
    VirtualScrollerModule,
  ],
  providers: [
    { provide: BASE_PATH, useValue: environment.apiUrl },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptorService, multi: true },
    { provide: LOCALE_ID, useValue: 'de-DE' }
  ],
  exports: [
    MatIconModule,
  ],
  bootstrap: [AppComponent]
})

export class AppModule {
}

function isGlossaryLink(href: string): boolean {
  return href.includes(GLOSSARY_IDENTIFIER);
}

export function markedOptionsFactory(): MarkedOptions {
  const renderer = new MarkedRenderer();

  const linkRenderer = renderer.link;
  renderer.link = (href, title, text) => {
    const html = linkRenderer.call(renderer, href, title, text);

    if (text.includes("Slideshow")) {
      const images = title.split(',');

      var htmlText = `
      <div class="slideshow-container">
      <div class="slideshow" id="slideshow" data-component="slideshow">`;

      for (let i = 0; i < images.length; i++) {
        htmlText += `<div id="slide" class="slide">
                     <img height=320 width=320 src="`+ images[i] + `" alt="">
                    </div>`;
      }


      htmlText += `
        <img class="slide-button-left" src="assets/media/hover_arrows_hero_left.svg" height="41" width="27"/>
        <img class="slide-button-right" src="assets/media/hover_arrows_hero_right.svg" height="41" width="27"/>`;


      htmlText += `<div class="slide-indicators">`;
      htmlText += `<span class="slide-indicators-bg">`;

      for (let i = 0; i < images.length; i++) {
        htmlText += `<span class="slide-indicator-touch" touch-slide=` + i + `> <span class="slide-indicator" data-slide=` + i + `></span></span>`;
      }
      htmlText += `</span></div>`;


      htmlText += `</div></div>`;

      return htmlText;
    }

    if (text.includes("<img")) {
      if (href.includes('flimmo.de')) {
        return '<a href="' + href + '">' + text + '</a>';
      } else {
        return '<a target="_blank" href="' + href + '">' + text + '</a>';
      }
    }
    if (isAbsoluteLinkFast(href)) {
      let withNewWindow = html.replace(/^<a /, '<a target="_blank" rel="nofollow" ');
      withNewWindow = withNewWindow.replace(/<\/a>/, '<i class="material-icons custom-material-icon-open-in-new">open_in_new</i><\/a>');
      return withNewWindow;
    }
    if (isGlossaryLink(href)) {
      return html.replace(/<\/a>/, ' <i class="material-icons custom-material-icon-info">info_outlined</i><\/a>');
    }

    if (text.includes("Big-Button-Line")) {
      const imageLink = text.substring(text.indexOf('|') + 1);
      const titletx = title.substring(0, title.indexOf('|'));
      const texttx = title.substring(title.indexOf('|') + 1);
      return `
      <a class="button-link" href="`+ href.substring(0, href.length - 1) + `">
        <div>
          <table class="bg-white big-button-link-table">
            <tr>
              <td class="button-link-td-img">
                <img class="border-radius-4px big-button-img" src="`+ imageLink + `">
              </td>
              <td class="button-link-td-text">
                <p>
                  <span class="mat-h3"><font color="#000000">`+ titletx + `</font></span>
                  <div class="mb1 listItemWidth"><font color="#000000">`+ texttx + `</font></div>
                </p>
              </td>
              <td class="button-link-td-icon">
                <i class="material-icons custom-material-keyboard_arrow_right">keyboard_arrow_right</i>
              </td>
            </tr>
          </table>
        </div>
      </a>
      `;
    }

    if (text.includes("Text-Button-Line")) {
      const color = text.substring(text.indexOf('|') + 1);

      var style = 'bg-white'
      if (color === 'grey') {
        style = 'mat-app-background-1';
      }

      return `
      <a class="button-link" href="`+ href.substring(0, href.length - 1) + `">
        <div>
          <table class="`+ style + ` text-button-link-table">
            <tr >
              <td class="px10px">
                <span class="mb1"><font color="#000000">`+ title + `</font></span>
              </td>
              <td class="button-link-td-icon">
                <i class="material-icons custom-material-keyboard_arrow_right ">keyboard_arrow_right</i>
              </td>
            </tr>
          </table>
        </div>
      </a>`;
    }

    return html;
  };



  return {
    renderer
  };
}
