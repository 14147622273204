<span fxLayout="row" [ngClass]="{'px20px': isHandset$ | async}" fxLayoutGap="20px" class="results-title w-100"
      fxLayoutAlign="space-between">
      <span class="primary-color mat-h1 mb-0 mt-0">
            <span *ngIf="(rowCount > 4 && (isHandset$ | async) === true) || rowCount > 10">
                  {{rowCount}}
            </span>

            {{'searchList.searchresults' |
            translate}}</span>
      <mat-checkbox class="cb" *ngIf="currentShowsFilterVisible" disableRipple color="primary"
            [checked]="currentShowsChecked" (change)="filterCurrentShows($event)">{{'searchList.currentShows' |
            translate}}
      </mat-checkbox>
</span>