<span fxLayout="row" fxFlex="100" fxLayoutAlign="center start">
  <span fxLayout="column" class="position-relative lane-body" fxFlex="0 1 900px" fxFlex.xs="100" fxFlex.sm="100">
    <span *ngIf="!loading && swimlane.items && swimlane.items.length > 0" fxLayout="column"
      fxLayoutAlign="center center" fxLayoutAlign.sm="center center" fxLayoutAlign.xs="start center">
      <span fxLayout="row" fxLayoutAlign="space-between center" class="carousel-fix-width w-100 " fxLayoutGap="10px"
        [ngClass]="{'px20px': isHandset}">

        <span *ngIf="tvLane" class="primary-color mat-h1 mb10px clickable" [routerLink]="['/tv', currentDate]">{{swimlane.title}}</span>
        <span *ngIf="!tvLane && swimlane.titleButton" class="primary-color mat-h1 mb10px clickable" [routerLink]='swimlane.titleButton.uri'>{{swimlane.title}}</span>
        <span *ngIf="!tvLane && !swimlane.titleButton" class="primary-color mat-h1 mb10px">{{swimlane.title}}</span>


        <a *ngIf="tvLane" class="f-hint-link icon-position text-decoration-none black mb10px text-nowrap" fxLayout="row"
          fxLayoutAlign="center center" [routerLink]="['/tv', currentDate]">
          <span>{{'swimlane.toTv' | translate | uppercase}}</span>
          <mat-icon color="primary" class="fs22">keyboard_arrow_right</mat-icon>
        </a>
        <a *ngIf="!tvLane && swimlane.titleButton"
          class="f-hint-link icon-position text-decoration-none black mb10px text-nowrap" fxLayout="row"
          fxLayoutAlign="center center" [routerLink]='swimlane.titleButton.uri'>
          <span>{{swimlane.titleButton.label | uppercase}}</span>
          <mat-icon color="primary" class="fs22">keyboard_arrow_right</mat-icon>
        </a>
      </span>
    </span>

    <img *ngIf="rightVisible()" src="assets/media/hover_arrows_swimlane_right.svg"
      class="absolute-zero right-arrow clickable" [alt]="'common.right' | translate"
      [ngStyle]="{'top.px': container.clientHeight/2}" (click)="scrollRight()">

    <img *ngIf="leftVisible()" src="assets/media/hover_arrows_swimlane_left.svg" [alt]="'common.left' | translate"
      [ngStyle]="{'top.px': container.clientHeight/2}" class="absolute-zero left-arrow clickable"
      (click)="scrollLeft()">

    <span fxLayout="row" fxLayoutAlign="start start" fxLayoutAlign.sm="start start" fxLayoutAlign.xs="start center"
      class="overflow-auto scrollbar-off" #container [input]="swimlane" scrollTracker
      [id]="swimlane?.title + ' ' + swimlane?.swimlaneclass + ' ' + swimlane?.items?.length">
      <span fxLayout="column" fxLayoutGap="20px" fxLayoutAlign="start" class="carousel-fix-width"
        *ngIf="!loading && swimlane.items && swimlane.items.length > 0">
        <span fxLayout="column" fxLayoutGap="20px" fxLayoutAlign="start start" fxFlex="0 1">
          <span fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="start start">
            <span *ngIf="isHandset" [ngStyle.sm]="{'width.px': 5}" [ngStyle.xs]="{'width.px': 5}"></span>
            <span *ngFor="let m of swimlane.items; trackBy: trackSwItems; let i = index" fxLayout="column"
              aria-label="Zum Text" class="mb-1 clickable"
              [ngClass]="{'carousel-card-big': bigCards, 'carousel-card': !bigCards}" #items (click)="navTo(m)">
              <span class="h-100 position-relative overflow-hidden">
                <img [src]="m.imagelow ? (m.imagelow | safe: 'resourceUrl') : PLACEHOLDER_IMAGE_WITH_PATH"
                  class="carousel-card-image" [alt]="m.title" (load)="imageLoaded = true" [class.loaded]="imageLoaded" (error)="onImageError($event)" fetchpriority="low" loading="lazy" >
                <span class="overlay-shade-b position-absolute overfill zero-pos">
                  <span fxLayout="column" fxLayoutAlign="space-between" class="h-100 p14px">
                    <span fxLayout="row" fxLayoutAlign="space-between">
                      <span *ngIf="m.itemclass !== sic.Redtext">
                        <img *ngIf="m.rating === 4" [height]="35"
                          [alt]="'searchList.selectors.ratingOptions.4' | translate" [width]="35"
                          src="assets/media/rating_badge.svg">
                        <img *ngIf="m.itemclass === sic.Ytbesprechung" [height]="35" [width]="35"
                          src="assets/media/ico_socialmedia.svg">
                      </span>
                      <span fxLayout="row" fxLayoutGap="6px" *ngIf="m.itemclass !== sic.Redtext">
                        <app-age-category-badge [width]="35" [height]="35" *ngIf="m.altersfreigabe && m.rating > 2"
                          [ageCategory]="m.altersfreigabe"></app-age-category-badge>
                        <app-traffic-lights [width]="18" [height]="46" [rating]="m.rating"
                          class=""></app-traffic-lights>
                      </span>

                    </span>
                    <span fxLayout="column" class="text-white">
                      <span class="f-preview-overline text-white">
                        {{m.itemclass === sic.Besprechung ? (m.genre | uppercase) : ""}} {{m.itemclass === sic.Redtext ?
                        m.topos : ""}}
                      </span>
                      <span class="f-preview-headline text-white overflow-hidden ellipsis">
                        <a *ngIf="m.itemclass === sic.Besprechung" class="nice-link"
                          [routerLink]="['/besprechung', m.id , flimmoMeta.prettifyUrl(m.title)]">{{m.title}}</a>
                        <a *ngIf="m.itemclass === sic.Redtext" class="nice-link" [routerLink]="['/redtext', m.id]">
                          {{m.title}}</a>
                        <a *ngIf="m.itemclass === sic.Ytbesprechung" class="nice-link"
                          [routerLink]="['/ytbesprechung', m.id]">{{m.title}}</a>
                        <a *ngIf="m.itemclass === sic.Special" class="nice-link"
                          [routerLink]="['/special', m.id]">{{m.title}}</a>
                      </span>
                      <span *ngIf="hasSrc(m)" class="f-preview-subline text-white src-box">
                        {{getText(m.srclist)}}
                      </span>
                    </span>
                  </span>
                </span>

              </span>
            </span>
            <span *ngIf="isHandset" [ngStyle.sm]="{'width.px': 5}" [ngStyle.xs]="{'width.px': 5}"></span>
          </span>
        </span>
      </span>
      <span *ngIf="loading">
        <mat-spinner mode="indeterminate" diameter="10px"></mat-spinner>
      </span>
    </span>
  </span>
</span>
